







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TextInput } from '../../form'
import Button from '@/infrastructure/ui/button.vue'
import { email, required } from 'vuelidate/lib/validators'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/infrastructure/store/user'
import { showSnackbar, SNACKBAR } from '@/infrastructure/EventBus/global-actions/showSnackbar'
import store from '@/infrastructure/store'
import { consume, PATH, provide } from '@/utils/provide-consum-decorator'
import { IUserStore } from '@/infrastructure/store/user/types'
import { ERROR_CODE } from '@/application/Errors'

@Component<EmailAndPassword>({
    components: {
        Button,
        TextInput
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required
        }
    }
})
@provide({
    [PATH.USER_API]: () => {
        return getModule(UserStore, store)
    }
})
export default class EmailAndPassword extends Vue {
    @consume(PATH.USER_API) authModule!: IUserStore

    @Prop({ default: 'Login' }) title!: string
    email = ''
    password = ''
    loading = false
    inexistingEmail = ''
    inexistingPassword = ''

    async submit(): Promise<void> {
        try {
            this.loading = true
            await this.authModule.loginUser({
                email: this.email,
                password: this.password
            })
            await this.$router.push({ name: 'boulder-list' })
        } catch (e) {
            if (e.message == ERROR_CODE.CREDENTIALS) {
                this.inexistingEmail = this.email
                this.inexistingPassword = this.password
                this.$v.$reset()
            } else {
                console.log(e)
                showSnackbar({
                    type: SNACKBAR.ERROR,
                    message: 'Sorry, da ist etwas schief gegangen.'
                })
            }
        } finally {
            this.loading = false
        }
    }

    get emailErrors(): string[] {
        const errors: string[] = []
        const { email } = this.$v
        if (!email.$dirty && this.inexistingEmail.length < 1) {
            return errors
        }

        !email.required && errors.push('Email wird benötigt')
        !email.email && errors.push('Email-Format ist ungültig')
        console.log('email model : ', email.$model)
        if (this.email === this.inexistingEmail) {
            errors.push('Zugangsdaten sind fehlerhaft')
            this.inexistingEmail = ''
        }

        return errors
    }

    get passwordErrors(): string[] {
        const errors: string[] = []
        const { password } = this.$v
        if (!password.$dirty && this.inexistingPassword.length < 1) {
            return errors
        }

        !password.required && errors.push('Passwort wird benötigt')

        if (this.password === this.inexistingPassword) {
            errors.push('Zugangsdaten sind fehlerhaft')
            this.inexistingPassword = ''
        }

        return errors
    }
}
