








import Vue from 'vue'
import { EmailAndPassword } from '@/components/login/EmailAndPassword'
import { Component } from 'vue-property-decorator'

@Component({
    components: {
        NameAndPassword: EmailAndPassword
    }
})
export default class Login extends Vue {}
